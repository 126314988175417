<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      statData: null,
      wallets: null,

      services: null,
    };
  },
  methods: {
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          icon: "bonus",
          title: "Total de Bônus",
          value: this.total.bonus
        },
        {
          icon: "balance",
          title: "Saldo Disponível",
          value: this.total.balance
        }
      ]
    },
    getServices() {
      api
        .get('crm/services')
        .then(response => {
          if (response.data.status == 'success') {
            this.services = response.data.list
          }
        })
    },
  },
  mounted() {
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getServices()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Link de indicação de captadores
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="'https://app.redecaptadores.com.br/' + account.user.username">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="'https://app.redecaptadores.com.br/' + account.user.username
            " v-on:click="$noty.success('O link foi copiado!')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="services && services.length > 0" class="row">
      <div class="col-md-4" v-for="(row, i) in services" :key="i">
        <div class="card card-service border-0 mb-3">
          <div class="card-body">
            <div v-if="row.cover" class="card-cover">
              <img :src="row.cover" />
            </div>
            <div class="card-description p-4">
              <h5>{{ row.name }}</h5>
              <div>{{ row.description }}</div>

              <div class="divisor mt-3 mb-2">
                <span>Divulgar</span>
              </div>
              <div class="d-flex align-item-center justify-content-center">
                <ShareNetwork class="btn btn-default btn-sm" network="whatsapp" url="" :title="'https://go.geraldopompa.com.br/' + row.slug + '/' + account.user.username">
                  Whatsapp
                </ShareNetwork>
                <button class="btn btn-default btn-sm mx-2" v-clipboard:copy="'https://go.geraldopompa.com.br/' + row.slug + '/' + account.user.username
                  " v-on:click="$noty.success('O link foi copiado!')">
                  Copiar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.card-service,
.card-service .card-body {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0 !important;
}

.card-service .card-body img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  aspect-ratio: 16 / 7;
  object-fit: cover;
}
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.divisor span {
  position: relative;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
  z-index: 2;
}

.divisor:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 52%;
  width: 100%;
  height: 1px;
  background: #c0c0c0;
}
</style>